import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';

class FeatureSection extends React.Component {
  render() {
    let { title, items } = this.props;

    return (
      <FeatureSection.Wrapper>
        {title && <h1>{title}</h1>}
        {items ? (
          <div>{items.map(i => <FeatureItem key={i.title} item={i} />)}</div>
        ) : (
          <div>{[0, 1, 2, 3].map(i => <FeatureItem key={i} item={i} />)}</div>
        )}
      </FeatureSection.Wrapper>
    );
  }

  static Wrapper = styled.div`
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      margin-bottom: 10px;
    }

    & > div {
      align-self: stretch;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  `;
}

class FeatureItem extends React.Component {
  render() {
    let { item } = this.props;

    if (!item.title) {
      return (
        <FeatureItem.Wrapper>
          <figure>
            <Widget.BgImage />
          </figure>

          <div
            style={{
              width: 50,
              height: 26,
              marginBottom: 10,
              backgroundColor: 'darkgrey',
            }}
          />

          <div
            style={{
              width: 100,
              height: 22,
              marginBottom: 10,
              backgroundColor: 'lightgrey',
            }}
          />

          <div
            style={{ width: 100, height: 22, backgroundColor: 'lightgrey' }}
          />
        </FeatureItem.Wrapper>
      );
    }

    return (
      <FeatureItem.Wrapper>
        {item.image ? (
          <figure>
            <Widget.BgImage image={item.image} />
          </figure>
        ) : (
          <figure
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <item.icon style={{ width: 100, height: 100 }} />
          </figure>
        )}

        <h3>{item.title}</h3>

        <p>{item.text}</p>
      </FeatureItem.Wrapper>
    );
  }

  static Wrapper = styled.div`
    flex-shrink: 0;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    & > figure {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      margin-bottom: 10px;
      overflow: hidden;
    }

    & > h3 {
      margin-bottom: 10px;
      text-align: center;
    }

    & > p {
      text-align: justify;
    }

    @media screen and (max-width: 900px) {
      width: 50%;
    }

    @media screen and (max-width: 319px) {
      width: 100%;
    }
  `;
}

export default FeatureSection;
