import React from 'react';
import { withPage } from '../Page';
import Banner from '../Components/Banner';
import WordingSection from '../Components/WordingSection';
import FeatureSection from '../Components/FeatureSection';

class VoicePage extends React.Component {
  render() {
    return (
      <div>
        <Banner image="" />

        <WordingSection />

        <FeatureSection />
      </div>
    );
  }
}

export default withPage(VoicePage);
