import React from 'react';
import styled from 'styled-components';

const WordingSection = props => {
  let { children, extraCss } = props;

  return (
    <Wrapper extraCss={extraCss}>
      <DecoLine />

      {children ? (
        <Content>{children}</Content>
      ) : (
        <Content>
          <div style={{ width: 150, marginBottom: 10 }} />

          <div style={{ width: 200, marginBottom: 10 }} />

          <div style={{ width: 250 }} />
        </Content>
      )}
      <DecoLine />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: aliceblue;

  ${props => props.extraCss || ''};
`;

const DecoLine = styled.div`
  width: 50px;
  height: 10px;
  background-color: tomato;
`;

const Content = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  text-align: center;
  white-space: pre-wrap;

  & > div {
    height: 22px;
    background-color: grey;
  }
`;

export default WordingSection;
